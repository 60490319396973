import { useEffect, useState, useCallback } from "react";
import { getCompanyList, getMessageTriggerList } from "../../services/adminApi";
import MessagesTable from "./MessagesTable";
import SendMessage from "./SendMessage";
import { useRecoilState } from "recoil";
import { spinnerState } from "../StationLogin/state";

const Messages = () => {
  const [companyListPayload] = useState({
    offset: 0,
    limit: 290,
    search_by: "",
  });

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useRecoilState(spinnerState);
  const [params, setParams] = useState({ offset: 0, limit: 10 });
  const [companyList, setCompanyList] = useState([]);
  const [messageList, setMessageList] = useState([]);
  const [editRecord, setEditRecord] = useState([]);

  // Centralized error logging
  const handleError = (err) => console.error("Error:", err);

  // Fetch message list
  const getMessageList = useCallback(() => {
    setLoading(true);
    getMessageTriggerList(params)
      .then((response) => setMessageList(response.data))
      .catch(handleError)
      .finally(() => setLoading(false));
  }, [params, setLoading]);

  // Fetch company list (Only once on mount)
  const fetchCompanyList = useCallback(() => {
    getCompanyList(companyListPayload)
      .then((response) => setCompanyList(response.data.company_list))
      .catch(handleError);
  }, [companyListPayload]);

  // Fetch company list only once, when the component mounts
  useEffect(() => {
    fetchCompanyList();
  }, [fetchCompanyList]);

  // Fetch message list whenever params change
  useEffect(() => {
    getMessageList();
  }, [getMessageList]);

  return (
    <div className="row mt-3 dashboard-background">
      <div className="d-flex justify-content-between">
        <h5>Messages</h5>
      </div>

      <div className="col-12 my-2 text-end">
        <SendMessage
          companyList={companyList}
          editRecord={editRecord}
          setEditRecord={setEditRecord}
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
          getMessageList={getMessageList}
        />
      </div>

      <MessagesTable
        messageList={messageList}
        setEditRecord={setEditRecord}
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        setParams={setParams}
        params={params}
      />
    </div>
  );
};

export default Messages;
