import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Input from "@material-ui/core/Input";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import Modal from "@material-ui/core/Modal";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import Snackbar from "@material-ui/core/Snackbar";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Tooltip from "@material-ui/core/Tooltip";
import DashboardIcon from "@material-ui/icons/Dashboard";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import MuiAlert from "@material-ui/lab/Alert";
import React, { useEffect, useState } from "react";
import "react-phone-number-input/style.css";
import { useRecoilState } from "recoil";
import ButtonCollection from "../../assets/images/button-collection.png";
import Edit from "../../assets/images/edit.png";
import ManageUsers from "../../assets/images/ManageUsers.png";
import Trash from "../../assets/images/trash.png";
import View from "../../assets/images/view.png";
import Config from "../../config.json";
import PhotoLibraryIcon from "@material-ui/icons/PhotoLibrary";
import {
  addCompany,
  deleteCompany,
  getCompanyList,
  getModuleList,
  updatePassword,
} from "../../services/adminApi";
import { spinnerState } from "../StationLogin/state";
import { IconButton } from "@material-ui/core";
// import FlashMessage from 'react-flash-message';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 275,
    maxWidth: 275,
  },
}));

const styles = (theme) => ({
  field: {
    margin: "10px 0",
  },
  countryList: {
    ...theme.typography.body1,
  },
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const CompanyList = (props) => {
  const [companyId, setCompanyId] = useState("");

  const [companyName, setCompanyName] = useState("");
  const [companyNameError, setCompanyNameError] = useState("");

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");

  const [contact, setContact] = useState("");
  const [contactError, setContactError] = useState("");

  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");

  const [moduleError, setModuleError] = useState("");

  const [companyListDisplay, setCompanyListDisplay] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [tableCount, setTableCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [addNewCompanyModalFlag, setAddNewCompanyModalFlag] = useState(false);
  const [changePasswordModalFlag, setChangePasswordModalFlag] = useState(false);
  const [selectedModuleModalFlag, setSelectedModuleModalFlag] = useState(false);
  const [deleteModalFlag, setDeleteModalFlag] = useState(false);
  const [selectedId, setSelectedId] = useState();

  const [addSneakBarFlag, setAddSneakBarFlag] = useState(false);
  const [deleteSneakBarFlag, setDeleteSneakBarFlag] = useState(false);
  const [changePasswordSneakBarFlag, setChangePasswordSneakBarFlag] =
    useState(false);

  const [companyListColumns, setCompanyListColumns] = useState([
    { name: "sr", title: "Sr. No." },
    { name: "name", title: "Name" },
    { name: "adminUsers", title: "Admin Users" },
    { name: "contact", title: "Contact" },
    { name: "dynamicForm", title: "Dynamic Form" },
    { name: "participantList", title: "Participants" },
    { name: "modules", title: "Modules" },
    { name: "checkInStations", title: "Check-in Stations" },
    { name: "companyPage", title: "Company Page" },
    { name: "manageUsers", title: "Manage users" },
    { name: "ga", title: "GA4 Dashboard" },
    { name: "station_login", title: "Station Login" },
    { name: "image", title: "Image Gallery" },
    { name: "action", title: "Action" },
  ]);

  const [editFlag, setEditFlag] = useState(false);
  const [addNewFlag, setAddNewFlag] = useState(false);
  const [totalSelected, setTotalSelected] = useState(0);
  const classes = useStyles();
  const theme = useTheme();
  const [moduleName, setModuleName] = useState([]);
  let [modulesId, setModulesId] = useState([]);

  const [modules, setModules] = useState([]);
  const [checkedModules, setCheckedModules] = useState([]);

  const [isAdmin, setIsAdmin] = useState(false);

  const [companyListPayload, setCompanyListPayload] = useState({
    // sort_by: 'sr',
    // order_by: 'desc',
    offset: 0,
    limit: 50,
    // status: 1,
    search_by: "",
  });

  useEffect(() => {
    getCompanyListHandler();
    getModuleListHandler();
  }, [tableCount]);

  useEffect(() => {
    getCompanyListHandler();
  }, [companyListPayload]);

  useEffect(() => {
    setCompanyName("");
    setCompanyNameError("");

    setContact("");
    setContactError("");

    setEmail("");
    setEmailError("");

    setModuleName([]);
    setModuleError("");
  }, [addNewFlag]);

  useEffect(() => {
    setPassword("");
    setPasswordError("");

    setConfirmPassword("");
    setConfirmPasswordError("");
  }, [changePasswordModalFlag]);

  const setCheckedModulesHandler = () => {
    let data = JSON.parse(localStorage.getItem("selectedCompany"));
    // console.log("Checked Modules", data.modules);
    data.modules ? setCheckedModules(data.modules) : setCheckedModules([]);
  };

  const getModuleListHandler = () => {
    getModuleList().then(async (response) => {
      // console.log(response);
      setModules(response.data);
    });
  };

  const [loading, setLoading] = useRecoilState(spinnerState);
  const getCompanyListHandler = () => {
    setLoading(true);
    getCompanyList(companyListPayload)
      .then(async (response) => {
        // console.log("company", response);
        let tempData = response.data.company_list;

        await setCompanies(tempData);
        setTableCount(response.data.count);

        const companyListTemp = [];
        tempData.map((ins, index) => {
          // setCompanyName(ins.company_name);
          // setEmail(ins.email);
          // setContact(ins.contact);
          // setModuleName(ins.modules);

          companyListTemp.push(
            <TableRow
              key={ins.id}
              // onClick={() => { localStorage.setItem('selectedCompany', JSON.stringify(ins)); props.history.push('/admin/userList') }}
            >
              <TableCell component="th" scope="row">
                {index + 1}
              </TableCell>
              <TableCell>{ins.company_name}</TableCell>
              <TableCell>{ins.email}</TableCell>
              <TableCell>{ins.contact}</TableCell>

              <TableCell>
                <div>
                  <Tooltip title="Dynamic Form">
                    <button
                      type="button"
                      className="trans-btn"
                      onClick={() => {
                        localStorage.setItem(
                          "selectedCompany",
                          JSON.stringify(ins)
                        );
                        props.history.push("/admin/dynamicForm");
                      }}
                    >
                      <img src={View} alt="" title="" />
                    </button>
                  </Tooltip>
                </div>
              </TableCell>
              <TableCell>
                <div>
                  <Tooltip title="Participants">
                    <button
                      type="button"
                      className="trans-btn"
                      onClick={() => {
                        localStorage.setItem(
                          "selectedCompany",
                          JSON.stringify(ins)
                        );
                        props.history.push("/admin/participantList");
                      }}
                    >
                      <img src={View} alt="" title="" />
                    </button>
                  </Tooltip>
                </div>
              </TableCell>
              <TableCell>
                <div>
                  <Tooltip title="Modules">
                    <button
                      type="button"
                      className="trans-btn"
                      // {document.getElementById("checkedmodules2")} ?
                      onClick={() => {
                        localStorage.setItem(
                          "selectedCompany",
                          JSON.stringify(ins)
                        );
                        setSelectedModuleModalFlag(true);
                        setCheckedModulesHandler();
                      }}
                      // : null
                    >
                      <img src={View} alt="" title="" />
                    </button>
                  </Tooltip>
                </div>
              </TableCell>
              <TableCell>
                <div>
                  <Tooltip title="Check-in Stations">
                    <button
                      type="button"
                      className="trans-btn"
                      id="stationBtn"
                      disabled={
                        ins.modules
                          ? !ins.modules.find((x) => x.id === 2)
                          : true
                      }
                      onClick={() => {
                        localStorage.setItem(
                          "selectedCompany",
                          JSON.stringify(ins)
                        );
                        props.history.push("/admin/stations");
                      }}
                    >
                      {ins.modules ? (
                        ins.modules.find((x) => x.id === 2) ? (
                          <img src={View} alt="" title="" />
                        ) : (
                          "NA"
                        )
                      ) : (
                        "NA"
                      )}
                    </button>
                  </Tooltip>
                </div>
              </TableCell>
              {/* <TableCell>
                                <div>
                                    <button type="button" className="trans-btn"
                                        onClick={() => { props.history.push('/admin/clientPage2') }}
                                    >
                                        <img src={View} alt="" title="" />
                                    </button>
                                </div>
                            </TableCell> */}
              <TableCell>
                <div>
                  <Tooltip title="Company Page">
                    <button
                      type="button"
                      className="trans-btn"
                      onClick={() => {
                        localStorage.setItem(
                          "selectedCompany",
                          JSON.stringify(ins)
                        );
                        props.history.push("/admin/ModuleForm");
                      }}
                    >
                      <img src={View} alt="" title="" />
                    </button>
                  </Tooltip>
                </div>
              </TableCell>
              <TableCell>
                <div>
                  <Tooltip title="Manage users">
                    <button
                      type="button"
                      className="trans-btn"
                      onClick={() => {
                        localStorage.setItem(
                          "selectedCompany",
                          JSON.stringify(ins)
                        );
                        props.history.push("/admin/users");
                      }}
                    >
                      <img src={ManageUsers} alt="" title="" />
                    </button>
                  </Tooltip>
                </div>
              </TableCell>
              <TableCell>
                <Tooltip title="Public Google Analytics Report">
                  <button
                    type="button"
                    className="trans-btn"
                    onClick={() =>
                      window.open(
                        `${Config.frontEndUrl}/public/ga4/dashboard/${ins.id}`
                      )
                    }
                  >
                    {/* <img src={View} alt="" title="" /> */}
                    <DashboardIcon style={{ color: "#19375a" }} />
                  </button>
                </Tooltip>
                <Tooltip title="Copy Link">
                  <button
                    type="button"
                    className="trans-btn ml-2 mr-2"
                    onClick={() => {
                      navigator.clipboard.writeText(
                        `${Config.frontEndUrl}/public/ga4/dashboard/${ins.id}`
                      );
                      setOpenSnackbar(true);
                    }}
                  >
                    {/* <img src={View} alt="" title="" /> */}
                    <FileCopyIcon style={{ color: "#19375a" }} />
                  </button>
                </Tooltip>
              </TableCell>
              <TableCell>
                <Tooltip title="Station Login">
                  <button
                    type="button"
                    className="trans-btn"
                    onClick={() =>
                      window.open(
                        `${Config.frontEndUrl}/stationLogin/${ins.id}`
                      )
                    }
                  >
                    {/* <img src={View} alt="" title="" /> */}
                    <ExitToAppIcon style={{ color: "#19375a" }} />
                  </button>
                </Tooltip>
                <Tooltip title="Copy Link">
                  <button
                    type="button"
                    className="trans-btn ml-2 mr-2"
                    onClick={() => {
                      navigator.clipboard.writeText(
                        `${Config.frontEndUrl}/stationLogin/${ins.id}`
                      );
                      setOpenSnackbar(true);
                    }}
                  >
                    {/* <img src={View} alt="" title="" /> */}
                    <FileCopyIcon style={{ color: "#19375a" }} />
                  </button>
                </Tooltip>
              </TableCell>
              <TableCell>
                <Tooltip title="Photo Wall">
                  <IconButton
                    type="IconButton"
                    className="trans-btn"
                    onClick={() => {
                      props.history.push(`/admin/gallary/${ins.id}`);
                    }}
                  >
                    <PhotoLibraryIcon />
                  </IconButton>
                </Tooltip>
              </TableCell>
              <TableCell colSpan={13}>
                <div className="action-btn">
                  <Tooltip title="Custom Buttons">
                    <button
                      type="button"
                      className="trans-btn"
                      onClick={() => {
                        localStorage.setItem(
                          "selectedCompanyDetails",
                          JSON.stringify(ins)
                        );
                        props.history.push("/admin/customButtons");
                      }}
                    >
                      <img src={ButtonCollection} alt="" title="" />
                      {/* <LockOpenIcon style={{ color: "#19375a" }} /> */}
                    </button>
                  </Tooltip>{" "}
                  <Tooltip title="Change Password">
                    <button
                      type="button"
                      className="trans-btn"
                      onClick={() => {
                        setChangePasswordModalFlag(true);
                        localStorage.setItem(
                          "selectedCompanyDetails",
                          JSON.stringify(ins)
                        );
                        setCompanyDetails();
                      }}
                    >
                      {/* <img src={LockOpenIcon} alt="" title="" /> */}
                      <LockOpenIcon style={{ color: "#19375a" }} />
                    </button>
                  </Tooltip>
                  <Tooltip title="Edit Company">
                    <button
                      type="button"
                      className="trans-btn"
                      onClick={() => {
                        setAddNewCompanyModalFlag(true);
                        setEditFlag(true);
                        setModuleName([]);
                        setModuleError("");
                        localStorage.setItem(
                          "selectedCompanyDetails",
                          JSON.stringify(ins)
                        );
                        setCompanyDetails();
                      }}
                    >
                      <img src={Edit} alt="" title="" />
                    </button>
                  </Tooltip>{" "}
                  <Tooltip title="Delete">
                    <button
                      type="button"
                      className="trans-btn"
                      onClick={() => {
                        setSelectedId(ins.id);
                        setDeleteModalFlag(true);
                      }}
                    >
                      <img src={Trash} alt="" title="" />
                    </button>
                  </Tooltip>
                  <Tooltip title="Participant Without NFC">
                    <button
                      type="button"
                      className="trans-btn"
                      onClick={() => {
                        localStorage.setItem(
                          "selectedCompany",
                          JSON.stringify(ins)
                        );
                        props.history.push("/admin/ParticipantListWithoutNfc");
                      }}
                    >
                      <img src={View} alt="" title="" />
                    </button>
                  </Tooltip>
                  <Tooltip title="Dnyamic NFC">
                    <button
                      type="button"
                      className="trans-btn"
                      onClick={() => {
                        localStorage.setItem(
                          "selectedCompany",
                          JSON.stringify(ins)
                        );
                        props.history.push(
                          `/dnyamicNfc/${ins.company_name.replace(
                            /\s+/g,
                            ""
                          )}/${ins.id}`
                        );
                      }}
                    >
                      <img src={View} alt="" title="" />
                    </button>
                  </Tooltip>
                </div>
              </TableCell>
            </TableRow>
          );
          if (index === tempData.length - 1) {
            setCompanyListDisplay(companyListTemp);
          }
        });
      })
      .finally(() => setLoading(false));
  };

  const setCompanyDetails = () => {
    let data = JSON.parse(localStorage.getItem("selectedCompanyDetails"));
    // console.log("company details", data);

    setCompanyId(data.id);
    setCompanyName(data.company_name);
    setEmail(data.email);
    setContact(data.contact);
    let tempArray = [];
    if (data.modules) {
      for (let i = 0; i < data.modules.length; i++) {
        tempArray.push(data.modules[i].name);
      }
    }
    setModuleName(tempArray);
    // console.log("moduleName", moduleName);
  };

  const textFieldChangeHandler = (e) => {
    let { name, value } = e.target;

    switch (name) {
      case "companyName":
        setCompanyName(value);
        setCompanyNameError("");

        break;

      case "email":
        setEmail(value);
        setEmailError("");

        break;

      case "contact":
        setContact(value);
        setContactError("");

        break;

      case "password":
        setPassword(value);
        setPasswordError("");

        break;

      case "confirmPassword":
        setConfirmPassword(value);
        setConfirmPasswordError("");

        break;

      default:
        console.log("");
    }
  };

  const addCompanyHandler = (id) => {
    modulesId = [];
    if (
      companyName.trim() == "" ||
      email.trim() == "" ||
      !Boolean(email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) ||
      contact.trim() == "" ||
      !moduleName.length ||
      !Boolean(
        contact.match(/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/i)
      )
    ) {
      if (companyName.trim() == "") {
        setCompanyNameError("Please enter client name");
      }

      if (
        email.trim() == "" ||
        !Boolean(email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i))
      ) {
        setEmailError("Please enter valid email");
      }

      if (
        contact.trim() == "" ||
        !Boolean(
          contact.match(/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/i)
        )
      ) {
        setContactError("Please enter valid phone number");
      }

      if (!moduleName.length) {
        setModuleError("Please select atleast one module");
      }
    } else {
      // console.log("module name", moduleName);

      for (let i = 0; i < moduleName.length; i++) {
        let tempArray = modules.filter((x) => x.name === moduleName[i]);
        // console.log(tempArray);
        modulesId.push(tempArray[0].id);
      }

      let tempObj = {
        id: id,
        name: companyName,
        email: email,
        contact: contact,
        modules: modulesId,
      };

      document.getElementById("addCompanySubmit").disabled = true;

      // console.log(tempObj);

      addCompany(tempObj)
        .then((response) => {
          // console.log(response);
          document.getElementById("addCompanySubmit").disabled = false;
          setAddSneakBarFlag(true);
          setAddNewCompanyModalFlag(false);
          setAddNewFlag(false);
          // setEditFlag(false);
          getCompanyListHandler();
        })
        .catch((error) => {
          console.log(error);
          setEmailError(error.message);
        });
    }
  };

  const handleChange = (event) => {
    // console.log(event.target.value)

    setModuleName(event.target.value);

    setTotalSelected(event.target.value.length);
  };

  // const handleCheckboxClick = () => {
  //     var checkBox = document.getElementById("checkedmodules2");
  //     console.log("checkbox", checkBox);

  //     if (checkBox.checked === true) {
  //         props.history.push('/admin/stations');
  //     }
  // }

  const deleteCompanyHandler = () => {
    deleteCompany(selectedId).then((response) => {
      // console.log(response)
      setDeleteModalFlag(false);
      setDeleteSneakBarFlag(true);
      getCompanyListHandler();
    });
  };

  const changePasswordHandler = () => {
    console.log(isAdmin);

    if (
      password == "" ||
      confirmPassword == "" ||
      password !== confirmPassword
    ) {
      if (password == "") {
        setPasswordError("Please enter valid New Password");
      }

      if (confirmPassword == "") {
        setConfirmPasswordError("Please enter Confirm New Password");
      }

      if (password !== confirmPassword) {
        setConfirmPasswordError(
          "New password and confirm password do not match"
        );
      }
    } else {
      // console.log(`${email} ${password}`)

      let tempObj = {};
      if (isAdmin) {
        tempObj = {
          isAdmin: isAdmin,
          password: password,
        };
      } else {
        tempObj = {
          company_id: companyId,
          password: password,
        };
      }

      updatePassword(tempObj)
        .then((response) => {
          // console.log(response);
          setChangePasswordModalFlag(false);
          setChangePasswordSneakBarFlag(true);
        })
        .catch((error) => {
          console.log(error);
          setPasswordError(error.message);
        });
    }
  };

  const searchByKeyword = (e) => {
    let tempCompanyListPayload = { ...companyListPayload };
    tempCompanyListPayload.search_by = e.target.value;
    // console.log("company search", tempCompanyListPayload);
    setCompanyListPayload(tempCompanyListPayload);
  };

  const createSortHandler = (property) => (event) => {
    let tempCompanyListPayload = { ...companyListPayload };
    tempCompanyListPayload.sort_by = property;
    tempCompanyListPayload.order_by =
      companyListPayload.order_by === "asc" ? "desc" : "asc";
    setCompanyListPayload(tempCompanyListPayload);
    // this.getClientReportsListHandler(this.inspectionListPayload);
  };

  const handleChangePage = (event, newPage) => {
    let tempCompanyListPayload = { ...companyListPayload };
    tempCompanyListPayload.offset = newPage + 1;

    setCompanyListPayload(tempCompanyListPayload);
    // this.getClientReportsListHandler(this.inspectionListPayload);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    let tempCompanyListPayload = { ...companyListPayload };
    tempCompanyListPayload.limit = parseInt(event.target.value);
    setCompanyListPayload(tempCompanyListPayload);
    // this.getClientReportsListHandler(this.inspectionListPayload);
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const [openSnackbar, setOpenSnackbar] = useState(false);

  return (
    <div className="">
      <Snackbar
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        autoHideDuration={2000}
        // TransitionComponent={transition}
        message="Copied Dashboard Link"
      />
      {/* <FlashMessage duration={2000}>
                <strong>Login Successful</strong>
            </FlashMessage> */}

      <div className="mr-t50">
        {/* <div className="page-title mr-t50 mr-l10">
                    <h3>Company List</h3>
                </div> */}
        <div className="data-head">
          <div className="page-title mr-l10">
            <h3>Company List</h3>
          </div>
          <div className="head-right-block">
            <div className="cus-search">
              <input
                type="text"
                name=""
                id=""
                value={companyListPayload.search_by}
                onChange={searchByKeyword}
                placeholder="Search"
              />
            </div>
            <div className="add-btn">
              <button
                type="button"
                className="s-blue-btn m-btn mr-l20 mr-r10"
                data-toggle="modal"
                data-target="#addInsuranceModal"
                onClick={() => {
                  setChangePasswordModalFlag(true);
                  setIsAdmin(true);
                }}
              >
                Change Password
              </button>
              <button
                type="button"
                className="s-blue-btn m-btn mr-l20 mr-r10"
                data-toggle="modal"
                data-target="#addInsuranceModal"
                onClick={() => {
                  setAddNewCompanyModalFlag(true);
                  setAddNewFlag(true);
                }}
              >
                + Add New
              </button>
            </div>
          </div>
        </div>
        <div className="cus-table">
          <div className="table-str">
            <TableContainer component={Paper} style={{ maxHeight: "75vh" }}>
              <Table aria-label="custom pagination table" stickyHeader>
                <TableHead>
                  <TableRow>
                    {companyListColumns.map((column) => (
                      <TableCell key={column.name} className="align-top">
                        {/* {column.name !== "action" ? (
                                                    <TableSortLabel
                                                        active={
                                                            companyListPayload
                                                                .sort_by === column.name
                                                        }
                                                        direction={
                                                            companyListPayload
                                                                .sort_by === column.name
                                                                ? companyListPayload
                                                                    .order_by
                                                                : "asc"
                                                        }
                                                        onClick={createSortHandler(
                                                            column.name
                                                        )}
                                                    >
                                                        {column.title}
                                                        {companyListPayload
                                                            .sort_by === column.name ? (
                                                            <span
                                                                style={{
                                                                    border: 0,
                                                                    clip: "rect(0 0 0 0)",
                                                                    height: 1,
                                                                    margin: -1,
                                                                    overflow: "hidden",
                                                                    padding: 0,
                                                                    position: "absolute",
                                                                    top: 20,
                                                                    width: 1,
                                                                }}
                                                            >
                                                                {companyListPayload
                                                                    .order_by === "desc"
                                                                    ? "sorted descending"
                                                                    : "sorted ascending"}
                                                            </span>
                                                        ) : null}
                                                    </TableSortLabel>
                                                ) : (
                                                    column.title
                                                )} */}
                        {(column.name = column.title)}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>{companyListDisplay}</TableBody>
              </Table>
            </TableContainer>
            <div style={{ float: "right" }}>
              <TablePagination
                rowsPerPageOptions={[
                  5,
                  10,
                  25,
                  50,
                  { label: "All", value: -1 },
                ]}
                colSpan={9}
                count={tableCount}
                className="text-end"
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { "aria-label": "rows per page" },
                  native: true,
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </div>
          </div>
        </div>
        <Modal
          open={addNewCompanyModalFlag}
          // disableBackdropClick={false}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div
            className="modal blue-modal fade"
            id="addInsuranceModal"
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal656 modal-dialog-centered modal-dialog-scrollable">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    Add Company
                  </h5>
                </div>
                <div className="modal-body">
                  <div className="ins-modal-body">
                    <form action="">
                      <div className="row">
                        <div className="col-sm-6">
                          <div className="form-group gray-fill-input mr-b30">
                            <label htmlFor="">Company Name</label>
                            <input
                              type="text"
                              className="form-control mr-t20"
                              name="companyName"
                              id=""
                              value={companyName}
                              onChange={textFieldChangeHandler}
                              placeholder="Company Name"
                            />
                            {companyNameError !== "" ? (
                              <p className="error-alert mr-b20">
                                {companyNameError}
                              </p>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group gray-fill-input mr-b30">
                            <label htmlFor="">Email</label>
                            <input
                              type="text"
                              className="form-control mr-t20"
                              name="email"
                              id=""
                              // disabled={editFlag}
                              value={email}
                              onChange={textFieldChangeHandler}
                              placeholder="Email"
                            />
                            {emailError !== "" ? (
                              <p className="error-alert mr-b20">{emailError}</p>
                            ) : null}
                          </div>
                        </div>

                        <div className="col-sm-6">
                          <div className="form-group gray-fill-input mr-b30">
                            <label>Contact</label>
                            <input
                              type="text"
                              className="form-control mr-t20"
                              name="contact"
                              id=""
                              value={contact}
                              onChange={textFieldChangeHandler}
                              placeholder="+44 1234567890"
                            />

                            {/* <input type='tel' className="form-control mr-t20"></input> */}
                            {/* <div>
                                                            <PhoneInput
                                                                className="form-control mr-t20"
                                                                country={'us'}
                                                                value={contact}
                                                                name="contact"
                                                                placeholder='Contact'
                                                                onChange={textFieldChangeHandler}
                                                            />
                                                        </div> */}
                            {/* <ReactPhoneInput
                                                            value={contact}
                                                            defaultCountry={'us'}
                                                            onChange={textFieldChangeHandler}
                                                            name='contact'
                                                            inputClass={classes.field}
                                                            dropdownClass={classes.countryList}
                                                            component={TextField}
                                                            inputExtraProps={{
                                                                margin: 'normal',
                                                                autoComplete: 'phone',
                                                                name: 'custom-username'
                                                            }}
                                                        /> */}
                            {contactError !== "" ? (
                              <p className="error-alert mr-b20">
                                {contactError}
                              </p>
                            ) : null}
                          </div>
                        </div>

                        <div className="col-sm-6">
                          <div className="form-group gray-fill-input mr-b30">
                            <FormControl className={classes.formControl}>
                              <label id="mutiple-module-checkbox-label">
                                Modules
                              </label>
                              <Select
                                className="form-control"
                                labelId="mutiple-module-checkbox-label"
                                id="mutiple-module-checkbox"
                                multiple
                                value={moduleName}
                                onChange={handleChange}
                                input={<Input />}
                                renderValue={(selected) => selected.join(", ")}
                                MenuProps={MenuProps}
                                disableUnderline
                              >
                                {modules.map((name) => (
                                  <MenuItem key={name.id} value={name.name}>
                                    <Checkbox
                                      checked={
                                        moduleName.indexOf(name.name) > -1
                                      }
                                    />
                                    <ListItemText primary={name.name} />
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                            {/* <div className="modules-list-item">
                                                                    <div className="left-section">Total Selected:</div>
                                                                    <div className="right-section">{totalSelected}</div>
                                                                </div> */}

                            {moduleError !== "" ? (
                              <p className="error-alert mr-b20">
                                {moduleError}
                              </p>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="modal-footer pd-b30">
                  <button
                    type="button"
                    className="s-blue-border-btn m-btn"
                    data-dismiss="modal"
                    onClick={() => {
                      setAddNewCompanyModalFlag(false);
                      setEditFlag(false);
                      setAddNewFlag(false);
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="s-blue-btn m-btn"
                    data-dismiss="modal"
                    id="addCompanySubmit"
                    onClick={() => {
                      addCompanyHandler(editFlag ? companyId : 0);
                    }}
                  >
                    {editFlag ? "Update Company" : "Add Company"}
                    {/* Add Company */}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal>

        <Modal
          open={selectedModuleModalFlag}
          // disableBackdropClick={false}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div
            className="modal blue-modal fade"
            id="addInsuranceModal"
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal656 modal-dialog-centered modal-dialog-scrollable">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    Selected Modules
                  </h5>
                </div>
                <div className="modal-body">
                  <div className="ins-modal-body">
                    <FormGroup row>
                      {modules.map(({ id, name }, index) => {
                        return (
                          <FormControlLabel
                            control={
                              <Checkbox
                                disabled
                                // onChange={handleChange}
                                name={name}
                                color="primary"
                                id={"checkedmodules" + id}
                                value={name}
                                checked={checkedModules.find(
                                  (x) => x.id === id
                                )}
                                // onClick={() => { props.history.push('/admin/stations') }}
                              />
                            }
                            className=""
                            id={"formControl" + id}
                            // onClick={handleCheckboxClick}
                            label={name}
                            // {id === 2  ?  <a href="">{name}</a> :
                            //}
                          />
                        );
                      })}
                    </FormGroup>
                  </div>
                </div>
                <div className="modal-footer pd-b30">
                  <button
                    type="button"
                    className="s-blue-border-btn m-btn"
                    data-dismiss="modal"
                    onClick={() => setSelectedModuleModalFlag(false)}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal>

        <Modal
          open={deleteModalFlag}
          disableBackdropClick={false}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div
            className="modal blue-modal fade"
            id="addInsuranceModal"
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal656 modal-dialog-centered modal-dialog-scrollable">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    Confirmation
                  </h5>
                </div>
                <div className="modal-body">
                  Want to delete Selected Company ?
                </div>

                <div className="modal-footer pd-b30">
                  <button
                    type="button"
                    className="s-blue-border-btn m-btn"
                    data-dismiss="modal"
                    onClick={() => setDeleteModalFlag(false)}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="s-blue-btn m-btn"
                    onClick={deleteCompanyHandler}
                  >
                    Confirm
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal>

        <Modal
          open={changePasswordModalFlag}
          // disableBackdropClick={false}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div
            className="modal blue-modal fade"
            id="addInsuranceModal"
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal656 modal-dialog-centered modal-dialog-scrollable">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    Change Password
                  </h5>
                </div>
                <div className="modal-body">
                  <div className="ins-modal-body">
                    <form action="">
                      <div className="row">
                        <div className="col-sm-6">
                          <div className="form-group gray-fill-input mr-b30">
                            <label htmlFor="">Password</label>
                            <input
                              type="text"
                              className="form-control mr-t20"
                              name="password"
                              id=""
                              value={password}
                              onChange={textFieldChangeHandler}
                              placeholder="Password"
                            />
                            {passwordError !== "" ? (
                              <p className="error-alert mr-b20">
                                {passwordError}
                              </p>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group gray-fill-input mr-b30">
                            <label htmlFor="">Confirm Password</label>
                            <input
                              type="password"
                              className="form-control mr-t20"
                              name="confirmPassword"
                              id=""
                              // disabled={editFlag}
                              value={confirmPassword}
                              onChange={textFieldChangeHandler}
                              placeholder="Confirm Password"
                            />
                            {confirmPasswordError !== "" ? (
                              <p className="error-alert mr-b20">
                                {confirmPasswordError}
                              </p>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="modal-footer pd-b30">
                  <button
                    type="button"
                    className="s-blue-border-btn m-btn"
                    data-dismiss="modal"
                    onClick={() => {
                      setChangePasswordModalFlag(false);
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="s-blue-btn m-btn"
                    data-dismiss="modal"
                    id="addCompanySubmit"
                    onClick={() => {
                      changePasswordHandler();
                    }}
                  >
                    {/* {editFlag ? 'Update Company' : 'Add Company'} */}
                    Change
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal>
        <Snackbar
          open={addSneakBarFlag}
          autoHideDuration={6000}
          onClose={() => {
            setAddSneakBarFlag(false);
            setEditFlag(false);
          }}
        >
          <Alert onClose={() => setAddSneakBarFlag(false)} severity="success">
            Company Is Successfully {editFlag ? "Updated" : "Added"}
          </Alert>
        </Snackbar>

        <Snackbar
          open={deleteSneakBarFlag}
          autoHideDuration={6000}
          onClose={() => setDeleteSneakBarFlag(false)}
        >
          <Alert
            onClose={() => setDeleteSneakBarFlag(false)}
            severity="success"
          >
            Company Is Successfully Deleted
          </Alert>
        </Snackbar>

        <Snackbar
          open={changePasswordSneakBarFlag}
          autoHideDuration={6000}
          onClose={() => setChangePasswordSneakBarFlag(false)}
        >
          <Alert
            onClose={() => setChangePasswordSneakBarFlag(false)}
            severity="success"
          >
            Password Is Successfully Updated
          </Alert>
        </Snackbar>
      </div>
    </div>
  );
};

export default CompanyList;
