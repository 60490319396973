import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useRecoilState } from "recoil";
import { gallaryImages } from "../StationLogin/state";
import GallaryStatus from "./GallaryStatus";
import ImageGallery from "./ImageGallery";
const GallaryWall = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [companyDetails, setCompanyDetails] = useRecoilState(gallaryImages);

  // Centralized error logging
  const history = useHistory();
  return (
    <div className="row mt-3 dashboard-background">
      <div className="d-flex ">
        <ArrowBackIcon
          className="mx-2 cursor"
          onClick={() => history.goBack()}
        />
        <h5>Gallery -{companyDetails?.companyName}</h5>
      </div>
      <div className="col-12 my-2 text-end">
        <GallaryStatus
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
        />
      </div>
      <ImageGallery />
      {/* <Index /> */}
    </div>
  );
};

export default GallaryWall;
