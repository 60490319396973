import { IconButton, Snackbar } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import SendIcon from "@material-ui/icons/Send";
import { Table, Tooltip } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { useRecoilState } from "recoil";
import { spinnerState } from "../StationLogin/state";
import { sendTextMessage } from "../../services/adminApi";
const MessagesTable = ({
  messageList,
  setEditRecord,
  isModalVisible,
  setIsModalVisible,
  setParams,
  params,
}) => {
  const [message, setMessage] = useState({
    title: "Success",
    open: false,
  });
  const [loading, setLoading] = useRecoilState(spinnerState);
  const sendTextMessageHandle = (id) => {
    setLoading(true);
    setMessage({
      title:
        "Message sending has started and will take time depending on the number of participants",
      open: true,
    });

    sendTextMessage(id)
      .then((res) => {
        setMessage({ title: "Messages sent successfully", open: true });
      })
      .catch((err) => {
        setMessage({ title: "Error to  trigger.", open: true });
      })
      .finally((final) => {
        setLoading(false);
      });
  };
  const selectRecord = (record) => {
    console.log(record);
    setIsModalVisible(true);
    setEditRecord(record);
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Event Name",
      dataIndex: "company_name",
      key: "company_name",
    },
    {
      title: "Date",
      dataIndex: "updated_at",
      key: "updated_at",
      render: (data) => moment(data).format("MM/DD/YYYY HH:mm"),
    },
    {
      title: "Twillio SID",
      dataIndex: "sid",
      key: "sid",
    },
    {
      title: "For User",
      dataIndex: "for_users",
      key: "for_users",
      className: "text-capitalize",
    },
    {
      title: "Action",
      dataIndex: "for_users",
      render: (record, list) => {
        return (
          <div>
            <Tooltip title={"Edit"}>
              <IconButton onClick={() => selectRecord(list)}>
                {" "}
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip
              title={"Send"}
              onClick={() => sendTextMessageHandle(list?.id)}
            >
              <IconButton>
                {" "}
                <SendIcon />
              </IconButton>
            </Tooltip>
          </div>
        );
      },
    },
  ];
  const handleTableChange = (pagination) => {
    console.log(pagination);
    setParams({
      ...params,
      limit: pagination.pageSize,
      offset: pagination.current - 1,
    });
  };

  return (
    <>
      <Snackbar
        open={message.open}
        onClose={() => setMessage({ title: "", open: false })}
        autoHideDuration={2000}
        color="white"
        // TransitionComponent={transition}
        message={message.title}
      />
      <Table
        columns={columns}
        dataSource={messageList?.records}
        pagination={{
          current: params.offset + 1,
          pageSize: params.limit,
          total: messageList?.total,
          showSizeChanger: true, // Allows user to change page size
        }}
        onChange={handleTableChange} // Triggered when page or pageSize changes
      />
    </>
  );
};

export default MessagesTable;
