import React from "react";
import { Route, Switch } from "react-router-dom";
import CompanyList from "../../components/companyList/companyList";
import UserList from "../../components/userList/userList";
import ParticipantList from "../../components/ParticipantList/ParticipantList";
// import ClientPage from '../../components/ClientPage/ClientPage';
// import ClientPage2 from '../../components/ClientPage/ClientPage2';

import SideNavigation from "../../components/sideNavigation/sideNavigation";
import TopNavigation from "../../components/topNavigation/topNavigation";
import ModuleForm from "../../components/ModuleForm/ModuleForm";
import Preview from "../../components/ModuleForm/Preview";
import Stations from "../../components/Stations/Stations";
import DynamicForm from "../../components/DynamicForm/DynamicForm";
import CustomButtons from "../../components/CustomButtons/CustomButtons";
import GoogleAnalyticsDashboard from "../../components/GoogleAnalyticsDashboard/GoogleAnalyticsDashboard";
import Ga4 from "../../components/GoogleAnalyticsDashboard/Ga4";
import AddFooter from "../../components/ModuleForm/AddFooter";
import ParticipantListWithoutNfc from "../../components/ParticipantList/ParticipantListWithoutNfc";
import Messages from "../../components/message/Messages";
import GallaryWall from "../../components/photowall/GallaryWall";

const ClientContainer = (props) => {
  return (
    <div>
      <SideNavigation />
      <section className="routing-wrap">
        <TopNavigation />

        <Switch>

          <Route path="/admin/companyList" exact component={CompanyList} />
          {/* <Route path='/admin/clientPage' exact component={ClientPage} /> */}
          {/* <Route path='/admin/clientPage2' exact component={ClientPage2} /> */}
          <Route path="/admin/moduleForm" exact component={ModuleForm} />
          <Route path="/admin/preview" exact component={Preview} />
          <Route
            path="/admin/participantList"
            exact
            component={ParticipantList}
          />
                  <Route path="/admin/gallary/:id"  component={GallaryWall} />

          <Route
            path="/admin/ParticipantListWithoutNfc"
            exact
            component={ParticipantListWithoutNfc}
          />
          <Route path="/admin/stations" component={Stations} />
          {/* <Route path="/admin/ga" component={GoogleAnalyticsDashboard} />
           */}{" "}
          <Route path="/admin/ga4" component={Ga4} />
           <Route path="/admin/messages" component={Messages} />
          <Route path="/admin/footer/:id" component={AddFooter} />
          <Route path="/admin/dynamicForm" component={DynamicForm} />
          {/* <Route path='/:companyName?/:id?' component={ClientPage2} /> */}
          <Route path="/admin/users" component={UserList} />
          <Route path="/admin/customButtons" component={CustomButtons} />

        </Switch>
      </section>
    </div>
  );
};

export default ClientContainer;
