import { atom } from "recoil";

export const loginState = atom({
  key: "loginState", // unique ID (with respect to other atoms/selectors)
  default: localStorage.getItem("stationLogin")
    ? JSON.parse(localStorage.getItem("stationLogin"))
    : {},
});
export const companyImageState = atom({
  key: "companyImage", // unique ID (with respect to other atoms/selectors)
  default: sessionStorage.getItem("image")
    ? sessionStorage.getItem("image")
    : null,
});
export const companyNameState = atom({
  key: "companyName", // unique ID (with respect to other atoms/selectors)
  default: sessionStorage.getItem("companyName")
    ? sessionStorage.getItem("companyName")
    : null,
});
export const spinnerState = atom({
  key: "loading", // unique ID (with respect to other atoms/selectors)
  default: false,
});

export const gallaryImages = atom({
  key: "list",
  default: {},
});
