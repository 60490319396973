import { Snackbar } from "@material-ui/core";
import { Button, Form, Input, Modal, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { SendMessagesApi } from "../../services/adminApi";
import { spinnerState } from "../StationLogin/state";

const { Option } = Select;
const userOptions = [
  { id: 1, name: "All" },
  { id: 2, name: "Registered" },
  { id: 3, name: "Non Registered" },
];

const SendMessage = ({
  companyList,
  editRecord,
  setEditRecord,
  isModalVisible,
  setIsModalVisible,
  getMessageList,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useRecoilState(spinnerState);
  const [message, setMessage] = useState({
    title: "Success",
    open: false,
  });
  const showModal = () => {
    setIsModalVisible(true);
    setEditRecord(null);
  };

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        console.log("Selected values:", values);
        form.resetFields(); // Reset form after submission
        setLoading(true);

        SendMessagesApi({ id: editRecord?.id ? editRecord?.id : 0, ...values })
          .then((res) => {
            setMessage({ title: "Trigger added successfully.", open: true });
            if (editRecord?.id) {
              getMessageList();
            }
          })
          .catch((err) => {
            setMessage({ title: "Error to  add trigger.", open: true });
          })
          .finally((final) => {
            setLoading(false);
            setIsModalVisible(false);
            setEditRecord(null);
          });
      })
      .catch((info) => {
        console.log("Validation Failed:", info);
      });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setEditRecord(null);
  };
  useEffect(() => {
    if (editRecord?.id)
      form.setFieldsValue({
        sid: editRecord.sid,
        company_id: editRecord.company_id,
        for_users: editRecord.for_users_index,
      });
  }, [editRecord]);
  return (
    <>
      <Snackbar
        open={message.open}
        onClose={() => setMessage({ title: "", open: false })}
        autoHideDuration={2000}
        color="white"
        // TransitionComponent={transition}
        message={message.title}
      />
      <Button type="primary" onClick={showModal}>
        Add Trigger
      </Button>

      <Modal
        title="Trigger Message"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Save"
      >
        <Form form={form} name="modalForm" layout="vertical">
          {/* Twilio SID Text Field */}
          <Form.Item
            name="sid"
            label="Twilio SID"
            rules={[
              { required: true, message: "Please enter the Twilio SID!" },
            ]}
          >
            <Input placeholder="Enter Twilio SID" />
          </Form.Item>
          {/* First Dropdown */}
          <Form.Item
            name="company_id"
            label="Event Name"
            rules={[{ required: true, message: "Please select an option!" }]}
          >
            <Select placeholder="Select an option">
              {companyList?.map((record) => (
                <Option value={record.id} key={record.id}>
                  {record?.company_name}
                </Option>
              ))}
            </Select>
          </Form.Item>

          {/* Second Dropdown */}
          <Form.Item
            name="for_users"
            label="For Users"
            rules={[{ required: true, message: "Please select an option!" }]}
          >
            <Select placeholder="Select an option">
              {userOptions?.map((record) => (
                <Option value={record.id} key={record.name}>
                  {record?.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default SendMessage;
