import { IconButton, Snackbar, Tooltip } from "@material-ui/core";
import { Settings } from "@material-ui/icons";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import TabIcon from "@material-ui/icons/Tab";
import { Button, Form, Input, Modal, Select, Tag, Upload } from "antd";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useRecoilState } from "recoil";
import Config from "../../config.json";
import { updateVisibilityApi } from "../../services/adminApi";
import { gallaryImages, spinnerState } from "../StationLogin/state";
import InfoIcon from "@material-ui/icons/Info";

const { Option } = Select;

const userOptions = [
  { id: 1, name: "Yes" },
  { id: 0, name: "No" },
];

const GallaryStatus = ({ isModalVisible, setIsModalVisible }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useRecoilState(spinnerState);
  const [companyDetails, setCompanyDetails] = useRecoilState(gallaryImages);
  const [tags, setTags] = useState([]);
  const [imgFlag, setImgFlag] = useState(false);
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [message, setMessage] = useState({
    title: "Success",
    open: false,
  });

  const param = useParams();

  const handleTagClose = (removedTag) => {
    setTags(tags.filter((tag) => tag !== removedTag));
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleInputConfirm = () => {
    if (inputValue && !tags.includes(inputValue)) {
      setTags([...tags, inputValue]);
    }
    setInputVisible(false);
    setInputValue("");
  };

  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    form
      .validateFields()
      .then(async (values) => {
        setLoading(true);
        setIsModalVisible(false);

        let base64Image = "";
        if (values.upload && values.upload[0]) {
          base64Image = await getBase64(values.upload[0].originFileObj);
        }

        const obj = {
          id: param.id,
          visibility: values?.visibility,
          nfc_ids: tags?.join(),
          image: base64Image
            ? base64Image
            : !imgFlag
            ? companyDetails?.gallery_header_img
              ? companyDetails?.gallery_header_img
              : ""
            : "",
        };
 
        updateVisibilityApi(obj)
          .then(() => {
            setMessage({ title: "Updated successfully.", open: true });
            window.location.reload();
          })
          .catch(() => {
            setMessage({ title: "Error updating visibility.", open: true });
          })
          .finally(() => {
            setLoading(false);
          });
      })
      .catch((info) => {
        console.log("Validation Failed:", info);
      });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    if (companyDetails) {
      form.setFieldValue("visibility", companyDetails?.photowall || 0);
      // form.setFieldValue(
      //   "upload",
      //   Config?.frontEndUrl + "/" + companyDetails?.gallery_header_img || ""
      // );
      setTags(companyDetails?.nfc_ids?.split(",") || []);
    }
  }, [companyDetails, form]);

  return (
    <>
      <Snackbar
        open={message.open}
        onClose={() => setMessage({ title: "", open: false })}
        autoHideDuration={2000}
        message={message.title}
      />

      <Tooltip title="Update Photowall Visibility">
        <IconButton onClick={showModal}>
          <Settings />
        </IconButton>
      </Tooltip>
      <Tooltip title="Copy Link">
        <IconButton
          onClick={() => {
            navigator.clipboard.writeText(
              `${Config.frontEndUrl}/photowall/${param.id}`
            );
            setMessage({ title: "Copied link", open: true });
          }}
        >
          <FileCopyIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Open in new tab">
        <IconButton
          onClick={() => {
            window.open(`${Config.frontEndUrl}/photowall/${param.id}`);
          }}
        >
          <TabIcon />
        </IconButton>
      </Tooltip>

      <Modal
        title="Update Photowall Visibility"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Save"
      >
        <Form form={form} name="modalForm" layout="vertical">
          {/* Visibility Dropdown */}
          <Form.Item
            name="visibility"
            label="Visibility"
            rules={[{ required: true, message: "Please select an option!" }]}
          >
            <Select placeholder="Select an option">
              {userOptions.map((record) => (
                <Option value={record.id} key={record.id}>
                  {record.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="upload"
            label="Header Image"
            valuePropName="fileList"
            getValueFromEvent={(e) =>
              Array.isArray(e) ? e : e?.fileList || []
            }
          >
            <Upload
              name="image"
              listType="picture"
              accept="image/*"
              maxCount={1}
              beforeUpload={(file) => {
                const isImage = file.type.startsWith("image/");
                const isLt2M = file.size / 1024 / 1024 < 2;
                if (!isImage) {
                  setMessage({
                    title: "You can only upload image files!",
                    open: true,
                  });
                }
                if (!isLt2M) {
                  setMessage({
                    title: "Image must be smaller than 2MB!",
                    open: true,
                  });
                }
                return isImage && isLt2M ? false : Upload.LIST_IGNORE;
              }}
              onRemove={() => {
                form.setFieldValue("upload", null);
                setImgFlag(true);
                setMessage({ title: "Image removed.", open: true });
              }}
              defaultFileList={
                companyDetails?.gallery_header_img
                  ? [
                      {
                        uid: "-1",
                        name: "Uploaded Image",
                        status: "done",
                        url:
                          Config?.imageApiUrl +
                          "/" +
                          companyDetails?.gallery_header_img,
                      },
                    ]
                  : []
              }
            >
              <Button>Click to Upload</Button>
            </Upload>
          </Form.Item>

          {/* Tags Input */}
          <Form.Item
            name="tags"
            label={
              <>
                NFC Tags{" "}
                <Tooltip title="These NFC IDs can delete photos from the wall">
                  <IconButton fontSize="small">
                    <InfoIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </>
            }
          >
            <div>
              {tags.map((tag) => (
                <Tag
                  key={tag}
                  closable
                  onClose={() => handleTagClose(tag)}
                  style={{ marginBottom: "5px" }}
                >
                  {tag}
                </Tag>
              ))}
              {inputVisible ? (
                <Input
                  size="small"
                  style={{ width: 120 }}
                  value={inputValue}
                  onChange={handleInputChange}
                  onBlur={handleInputConfirm}
                  onPressEnter={handleInputConfirm}
                />
              ) : (
                <Button
                  size="small"
                  type="dashed"
                  onClick={() => setInputVisible(true)}
                >
                  + Add Tag
                </Button>
              )}
            </div>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default GallaryStatus;
