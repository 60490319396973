import { Button, Divider, Empty, message, Popconfirm, Skeleton } from "antd";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import Gallery from "react-photo-gallery";
import {
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { useRecoilState } from "recoil";
import Config from "../../config.json";
import {
  deleteImageFromWallApi,
  getGallaryImageAPI,
} from "../../services/adminApi";
import { gallaryImages, spinnerState } from "../StationLogin/state";
import { IconButton } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";

const ImageGallery = () => {
  const [records, setRecords] = useState([]);
  const [companyDetails, setCompanyDetails] = useRecoilState(gallaryImages);
  const [loading, setLoading] = useRecoilState(spinnerState);
  const { id } = useParams();

  const location = useLocation();
  const history = useHistory();
  // Parse query parameters using URLSearchParams
  const searchParams = new URLSearchParams(location.search);

  // Get a specific query parameter
  const event_id = searchParams.get("event_id"); // Replace 'paramName' with your query param
  const nfc_id = searchParams.get("nfc_id"); // Replace 'paramName' with your query param
  console.log(event_id, nfc_id);
  // Load image dimensions and include id in the returned object
  const loadImageWithDimensions = useCallback((image) => {
    const { id, url } = image;
    const tempUrl = `${Config.imageApiUrl}/${url}`;
    return new Promise((resolve) => {
      const img = new Image();
      img.src = tempUrl;
      // img.onload = () => {
      resolve({
        id,
        src: tempUrl,
        width: img.naturalWidth || 1,
        height: img.naturalHeight || 1,
        originalUrl: tempUrl,
      });
      // };
      img.onerror = () => {
        console.error(`Failed to load image: ${tempUrl}`);
        resolve(null);
      };
    });
  }, []);

  // Fetch images from the API
  const getImages = useCallback(async () => {
    try {
      setLoading(true);
      // const res = await axios.get(
      //   `http://localhost:6003/api/v1.0/client/photo/get-photowall/${id}`
      // );
      const res = await getGallaryImageAPI(id);
      const list = res?.data?.list || [];

      const tempRecords = await Promise.all(
        list.map(async (record) => {
          const imagesWithIds = record?.urls || [];
          const loadedPhotos = await Promise.all(
            imagesWithIds.map(loadImageWithDimensions)
          );
          return {
            date: record.date,
            images: loadedPhotos
              ?.sort((a, b) => a.id - b.id)
              ?.reverse()
              ?.filter(Boolean),
          };
        })
      );
      setCompanyDetails(res?.data?.details);
      setRecords(tempRecords);
    } catch (error) {
      console.error("Error fetching images:", error);
    } finally {
      setLoading(false);
    }
  }, [id, loadImageWithDimensions, setLoading]);

  useEffect(() => {
    getImages();
  }, [getImages]);

  // Function to delete an image using its id
  // Function to delete an image using its id
  const deleteImage = async (imageId) => {
    try {
      setLoading(true);
      await deleteImageFromWallApi(imageId);
      message.success("Image deleted successfully!");
      getImages();
    } catch (error) {
      console.error("Error deleting image:", error);
      message.error("Failed to delete the image.");
    } finally {
      setLoading(false);
    }
  };

  // Custom render function to add a delete button on each image
  const renderImage = ({ photo, index }) => (
    <div style={{ position: "relative", margin: "2px" }} key={photo.id}>
      <img
        src={photo?.src}
        width={photo?.width}
        height={photo?.height}
        style={{
          display: "block",
          borderRadius: "5px",
          "object-fit": "scale-down",
          background: "#fff",
        }}
        alt={`photo-${index}`}
        loading="lazy"
      />
      {companyDetails?.nfc_ids?.split(",")?.length &&
      companyDetails?.nfc_ids?.split(",")?.includes(nfc_id) ? (
        <Popconfirm
          title="Are you sure you want to delete this image?"
          onConfirm={() => deleteImage(photo?.id)}
          okText="Yes"
          cancelText="No"
        >
          <IconButton
            style={{
              position: "absolute",
              top: 10,
              right: 10,
              backgroundColor: "rgba(255, 255, 255, 0.8)",
            }}
            size="small"
          >
            <DeleteIcon color="primary" />
          </IconButton>
        </Popconfirm>
      ) : null}
    </div>
  );

  return (
    <div>
      {event_id && nfc_id ? (
        <button
          className={`${
            event_id ? event_id + "_image-gallery" : ""
          }  image-gallery  btn btn-link`}
          style={{ textDecoration: "none" }}
          onClick={() => history.push(`/${event_id}/${nfc_id}`)}
        >
          Back{" "}
        </button>
      ) : null}
      <div>
        {" "}
        {companyDetails?.gallery_header_img ? (
          <img
            src={Config?.imageApiUrl + "/" + companyDetails?.gallery_header_img}
            alt=""
            width={"100%"}
          ></img>
        ) : null}
      </div>
      {loading ? (
        <Skeleton active paragraph={{ rows: 5 }} />
      ) : companyDetails?.photowall == 0 ? (
        <Empty
          className="mx-auto"
          description="You can't access the photo wall."
        />
      ) : records?.length > 0 ? (
        records?.map((record, index) => (
          <div key={index}>
            <Divider style={{ borderColor: "#7cb305" }}>
              {moment(record.date).format("MMMM Do YYYY")}
            </Divider>
            {record.images.length > 0 ? (
              <Gallery photos={record.images} renderImage={renderImage} />
            ) : (
              // renderImage
              <p>No images available</p>
            )}
          </div>
        ))
      ) : (
        <Empty description="No data found" />
      )}
    </div>
  );
};

export default ImageGallery;
